
import { Height } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';

type ICard = {
    filteredData: any
    typeKey?: any
    generalData: any
    height: number
    lineSize: string,
    positionLine?: string,
    isLargeChart?: boolean
    positionLineResponsive?:string
}
type IChart = {
    generalData: number[],
    filteredData: number[],
    months: string[],
    height: number
    isLargeChart?: boolean
}
const ChartDash = ({ generalData, height, months, isLargeChart, filteredData }: IChart) => {
    let generalSerieName = 'IFOZ Geral da Organização'


    let series = [{
        name: generalSerieName,
        data: generalData,
        color: '#2B81FF'
    }];

    if(filteredData.length > 0) {
        series.push({
            name: "IFOZ Baseado nos Filtros",
            data: filteredData,
            color: "#FC7100"
        })
    }

    return (
        <Chart 
        options={{
            chart: {
                toolbar: {
                    show: false
                },
                animations: {
                    enabled: true,
                    
                   
                },
                zoom: {
                    enabled: false,
                },
            },
            dataLabels: {
                enabled: (series.length == 1),
                offsetX: 0,
                offsetY: -8,
                formatter: (value: number | any) => {
                    return (value || value == 0) ? `${value}%` : ``; // Isso adicionará um sinal de porcentagem ao final do valor
                },
                background: {
                    enabled: false,
                    foreColor: '#fff',
                    padding: 4,
                    borderRadius: 2,
                    borderWidth: 1,
                    borderColor: '#fff',
                    opacity: 0.9,

                },
                style: {
                    fontSize: '12px',
                    colors: ['#3C4043'],
                },


            },
            stroke: {
                curve: 'smooth',
                colors: ["#2B81FF", "#FC7100"]
            },
            responsive: isLargeChart
                ? []
                : [{
                breakpoint: 1313,
                options: {
                        xaxis: {
                            categories: months,
                            tickPlacement: 'between',
                            labels: {
                                rotate: -45,
                                rotateAlways: true,
                                style: { colors: Array(12).fill('#878B8E') },
                            }
                        },
                    },
                },{
                    breakpoint:324,
                    options: {
                        legend: {
                            show: true,
                            position: 'top',
                            fontSize: '10px'
                        }, 
                    }
                }
        
            ],
            xaxis: {
                categories: months,
                tickPlacement: 'between',
                labels: {
                    rotate: isLargeChart ? 0 : -45,
                    rotateAlways: false, 
                    style: {
                        colors: ['#878B8E', '#878B8E','#878B8E','#878B8E','#878B8E','#878B8E','#878B8E','#878B8E','#878B8E','#878B8E','#878B8E','#878B8E'],
                    }
                }
            },
            yaxis: {
                opposite: false,
                tickAmount: 4,
                min: 0,
                max: 100,
                labels: {
                    style: {
                        colors: ['#3C4043']
                    },
                    formatter: (value: number | any) => {
                        return (value || value == 0) ? `${value}%` : `- -`; // Isso adicionará um sinal de porcentagem ao final do valor
                    }
                }
            },
            fill: {
                type: 'solid',
                colors: ['#D9D9D9']
            },
            annotations: {
                yaxis: [
                    {
                      y: 70,
                      strokeDashArray: 0,
                      borderColor: '#8fff1d',
                      borderWidth: 4,
                      label: {
                        borderColor: '#8fff1d',
                        style: {
                          color: '#3C4043',
                          background: '#8fff1d',
                          fontSize: '12px',
                        },
                        text: 'Bom',
                      }
                    }
                  ],

            },

            markers: {
                size: 6,
                colors: ["#2491FF", "#FC7100"],
                strokeColors: '#CACBDA',
                strokeWidth: 2,
                hover: {
                    size: 7, // Tamanho dos marcadores quando o mouse passa sobre eles
                }
            },
           
            tooltip: {
                enabled: true,
                x: {
                    show: true
                },
                y: {
                    formatter: (value: number | any) => {
                        return (value || value == 0) ? `${value}%` : `Dados insuficientes`; // Isso adicionará um sinal de porcentagem ao final do valor
                    }
                },
            },
            grid: {
                show: true,

                xaxis: {
                    lines: {
                        show: true
                    }
                },
                yaxis: {
                    lines: {
                        show: true,
                    }
                },
                row: {
                    colors: ['rgba(241, 241, 241, 1)'],
                    opacity: 0.5
                },
                column: {
                    colors: ['rgba(241, 241, 241, 1)'],
                    opacity: 0.5
                },
            },
            legend: {
                show: true,
                position: 'top',
                fontSize: '12px'
            }, 
            



        }} series={series} type="line" height={height} />

    )

};

const DashboardChart = ({ typeKey, generalData, height, lineSize, positionLine, isLargeChart, filteredData, positionLineResponsive }: ICard) => {
    const monthCurt:any= {
        'Janeiro': 'Jan',
        'Fevereiro': 'Fev',
        'Março': 'Mar',
        'Abril': 'Abr',
        'Maio': 'Mai',
        'Junho': 'Jun',
        'Julho': 'Jul',
        'Agosto': 'Ago',
        'Setembro': 'Set',
        'Outubro': 'Out',
        'Novembro': 'Nov',
        'Dezembro': 'Dez'
    };
    const abbreviatedData = generalData.map((item:any) => ({
        ...item,
        month: monthCurt[item.month] 
    }));
    const monthMapping: any = {
        'Jan': 1,
        'Fev': 2,
        'Mar': 3,
        'Abr': 4,
        'Mai': 5,
        'Jun': 6,
        'Jul': 7,
        'Ago': 8,
        'Set': 9,
        'Out': 10,
        'Nov': 11,
        'Dez': 12
    };
    abbreviatedData.sort((a: any, b: any) => monthMapping[a.month] - monthMapping[b.month]);
    const quantities = abbreviatedData.map((item:any) => {
        return item.result == null ? 0 : +item.result.toFixed(2);
    });

    const [dynamicHeight, setDynamicHeight] = useState(lineSize);
    const [dynamicPosition, setDynamicPosition] = useState(positionLine);


    const handleResize = () => {
        const hasFilter = filteredData.length > 0;
        const isMobile = window.matchMedia("(max-width: 446px)").matches;
    
        if (isLargeChart) {
            if (hasFilter) { // verifica se o filtro está ativo para poder setar o position do grafico comparativo durante o periodo
                setDynamicPosition('15%'); 
            } else{
                setDynamicPosition(positionLine);
            }

            if (window.matchMedia("(max-width: 1314px)").matches) {
                setDynamicHeight(hasFilter ? "300px" : "335px");
            } else {
                setDynamicHeight(hasFilter ? "300px" : lineSize);
                // setDynamicPosition(hasFilter ? "15%" : positionLine)
            }
        } else {
            if (isMobile) {
                setDynamicHeight(hasFilter ? "165px" : "210px");
                setDynamicPosition( hasFilter ? "24.8%": positionLine);

            } else if (window.matchMedia("(max-width: 1314px)").matches) {
                setDynamicHeight(hasFilter ? "180px" : "210px");
                setDynamicPosition(hasFilter ? positionLineResponsive : positionLine);
            } else {
                setDynamicHeight(hasFilter ? "202px" : lineSize);
                setDynamicPosition(hasFilter ? positionLineResponsive : positionLine);
            }
        }
    };    
    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [filteredData, lineSize, positionLine, isLargeChart]);
    

      
    const months = abbreviatedData.map((item: { month: any; }) => item.month);

    // console.log(data.map((item: any) => monthCurt[item.month]))
    
    return (
        <div style={{ position: "relative" }}>
            <div style={{
                width: '5px',
                height:`${dynamicHeight}`,
                position: 'absolute',
                top:`${dynamicPosition}`,
                left: '50px',
                right: 0,
                bottom: 0,
                background: 'linear-gradient(to bottom, #00920F 0%, #00FF19 25%, #ECFF20 35%, #F1C118 45%, #F00 70%, #F00 100%)'
               }}> 
            </div>
            <ChartDash isLargeChart={isLargeChart} height={height} key={typeKey} months={generalData.map((item: any) => monthCurt[item.month] + "/" + item.year.toString().slice(-2)).reverse()} generalData={generalData.map((item: any) => (item.result ? Number(item.result.toFixed(1)) : item.result)).reverse()} filteredData={filteredData.map((item: any) => (item.result ? Number(item.result.toFixed(1)) : item.result)).reverse()} />
        </div>
    )


}

export default DashboardChart

