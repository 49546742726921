import { InsightsMonthData } from '../../interfaces/insights.interface'
import requestBack from '../config/request-back'

/**
 * @deprecated Utilize a função `getDimensionHistoricByParameters`
 */
const getEPHistoric= async (companyId: any, month:string, year:string) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-dimension-ep-historic/${companyId}/${month}/${year}`,
		vtAuth: true,
	})

	return response
}

const getDimensionHistoricByParameters =  async (companyId: any, month: string, year: string, dimensionId: number, filters?: any) => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-evolution-ifoz-dimension-builder`,
		vtAuth: true,
		data: {company_id: companyId, month, year, dimension_id: dimensionId, ...filters},
	})

	return response
}

/**
 * @deprecated Utilize a função `getDimensionHistoricByParameters`
 */
const getENHistoric= async (companyId: any, month:string, year:string) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-dimension-en-historic/${companyId}/${month}/${year}`,
		vtAuth: true,
	})
	return response
}

/**
 * @deprecated Utilize a função `getDimensionHistoricByParameters`
 */
const getREHistoric= async (companyId: any, month:string, year:string) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-dimension-re-historic/${companyId}/${month}/${year}`,
		vtAuth: true,
	})

	return response
}

/**
 * @deprecated Utilize a função `getDimensionHistoricByParameters`
 */
const getSIHistoric= async (companyId: any, month:string, year:string) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-dimension-si-historic/${companyId}/${month}/${year}`,
		vtAuth: true,
	})

	return response
}

/**
 * @deprecated Utilize a função `getDimensionHistoricByParameters`
 */
const getREAHistoric= async (companyId: any, month:string, year:string) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-dimension-rea-historic/${companyId}/${month}/${year}`,
		vtAuth: true,
	})

	return response
}

/**
 * @deprecated Utilize a função `getDimensionHistoricByParameters`
 */
const getVITHistoric= async (companyId: any, month:string, year:string) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-dimension-vit-historic/${companyId}/${month}/${year}`,
		vtAuth: true,
	})

	return response
}

/**
 * @deprecated Utilize a função `getIFOZHistoricByParameters`
 */
const getIFOZHistoric= async (companyId: any, month:string, year:string) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-evolution-ifoz-by-company/${companyId}/${month}/${year}`,
		vtAuth: true,
	})

	return response
}

const getIFOZHistoricByParameters =  async (companyId: any, month: string, year: string, filters?: any) => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-evolution-ifoz-builder`,
		vtAuth: true,
		data: {company_id: companyId, month, year, ...filters},
	})

	return response
}

const getInsightsByParameters =  async (companyId: any, month: string, year: string, monthsRange: number, filters?: any): Promise<InsightsMonthData[]> => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-evolution-insights-builder`,
		vtAuth: true,
		data: {company_id: companyId, month, year, monthsRange, ...filters},
	})

	return response
}

/**
 * @deprecated Utilize a função `getIAPHistoricByParameters`
 */
const getIPAHistoric= async (companyId: any, month:string, year:string) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-evolution-ipa-by-company/${companyId}/${month}/${year}`,
		vtAuth: true,
	})

	return response
}

const getIAPHistoricByParameters =  async (companyId: any, month: string, year: string, filters?: any) => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-evolution-iap-builder`,
		vtAuth: true,
		data: {company_id: companyId, month, year, ...filters},
	})

	return response
}

/**
 * @deprecated Utilize a função `getIEPHistoricByParameters`
 */
const getIEPHistoric= async (companyId: any, month:string, year:string) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-evolution-iep-by-company/${companyId}/${month}/${year}`,
		vtAuth: true,
	})

	return response
}

const getIEPHistoricByParameters =  async (companyId: any, month: string, year: string, filters?: any) => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-evolution-iep-builder`,
		vtAuth: true,
		data: {company_id: companyId, month, year, ...filters},
	})

	return response
}

/**
 * @deprecated Utilize a função `getIFCZHistoricByParameters`
 */
const getIFCZHistoric= async (companyId: any, month:string, year:string) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-evolution-ifcz-by-company/${companyId}/${month}/${year}`,
		vtAuth: true,
	})

	return response
}

const getIFCZHistoricByParameters =  async (companyId: any, month: string, year: string, filters?: any) => {
	let response = await requestBack({
		method: 'POST',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-evolution-ifcz-builder`,
		vtAuth: true,
		data: {company_id: companyId, month, year, ...filters},
	})

	return response
}



const getIPACompany= async (companyId: any, month:string, year:string) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-ipa-by-company/${companyId}/${month}/${year}`,
		vtAuth: true,
	})

	return response
}

const getIPAGroupCompany =  async (companyId: any, groupId:any,  month:string, year:string) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-ipa-by-group/${companyId}/${groupId}/${month}/${year}`,
		vtAuth: true,
	})

	return response
}

const getIPASexCompany =  async (companyId: any, sex:string,  month:string, year:string) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-ipa-by-representation/${companyId}/${sex}/${month}/${year}`,
		vtAuth: true,
	})

	return response
}

const getIFOZCompany =  async (companyId: any, month:string, year:string) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-ifoz-by-company/${companyId}/${month}/${year}`,
		vtAuth: true,
	})

	return response
}


const getIFOZGroupCompany =  async (companyId: any, groupId:any,  month:string, year:string) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-ifoz-by-group/${companyId}/${groupId}/${month}/${year}`,
		vtAuth: true,
	})

	return response
}

const getIFOZSexCompany =  async (companyId: any, sex:string,  month:string, year:string) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-ifoz-by-representation/${companyId}/${sex}/${month}/${year}`,
		vtAuth: true,
	})

	return response
}


const getPointsAtention =  async (companyId: any, month:string, year:string) => {
	let response = await requestBack({
		method: 'GET',
		url: process.env.REACT_APP_SERVICES_BACK_HOST + `/statistic-points-atention/${companyId}/${month}/${year}`,
		vtAuth: true,
	})

	return response
}



export { getIPACompany, getIFOZCompany, getDimensionHistoricByParameters, getEPHistoric, getIFOZHistoric, getIFOZHistoricByParameters, getInsightsByParameters, getIPAHistoric, getIAPHistoricByParameters, getENHistoric, getREAHistoric, getREHistoric, getSIHistoric, getVITHistoric, getIFOZGroupCompany, getIFOZSexCompany, getIPAGroupCompany, getIPASexCompany, getPointsAtention, getIEPHistoric, getIEPHistoricByParameters, getIFCZHistoric, getIFCZHistoricByParameters }
