import { endOfMonth, getDate } from "date-fns"
import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useLocation, useNavigate } from "react-router-dom"
import CustomCard from "../../components/customCard"
import Header from "../../components/header"
import { useAuth } from "../../context/AuthContext"
import { useQuestions } from "../../context/QuestionContext"
import { useToast } from "../../context/Toast"
import { getCompanyId } from "../../services/company/company"
import { getUserByEmail } from "../../services/user/user"
import { InsideCardStyles, StartButton, StyledCardSubtitle, StyledCardText, StyledCardTitle, CardEmployee, StyledCardTitleEmployee } from "./styles"
import { UserStatus } from "../../enums/user"


const Welcome: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user, saveDataVerifyOnStorage, saveDataSignUpOnStorage, SignOut } = useAuth()
  const { answerId } = useQuestions()
  const [urlParam, setToken] = useState({
    token: null,
    email: null,
  })
  const { showToast } = useToast()

  useEffect(() => {

    const getTokenFromUrl = async () => {
      const queryParams = new URLSearchParams(location.search)
      const url: any = {}
      if (queryParams.get('token')) url.verifyToken = queryParams.get('token')
      if (queryParams.get('email')) url.user = {
        email: queryParams.get('email'),
      }
      if (queryParams.get('confirmation_code')) {
        url.confirmationCode = queryParams.get('confirmation_code')
      } else {
        delete url.confirmationCode;
      }
      if (Object.keys(url).length !== 0) {
        saveDataVerifyOnStorage(url)
        if (queryParams.get('email')) {
          let result = null
          try {
            result = await getUserByEmail(queryParams.get('email'))
          } catch (error) {
            console.warn(error);
          }
          const dataToSave = {
            id: result?.id,
            email: result?.email,
            status: result?.status,
            first_name: result?.first_name,
            last_name: result?.last_name,
            company_id: result?.company?.Item?.id,
            company_name: result?.company?.Item?.name_company,
            role: result?.role,
          }
          saveDataSignUpOnStorage(dataToSave)

          
        }
      }

    }

    getTokenFromUrl()

  }, [location])



  return (

    <>
      <Header />

      <Container fluid className="d-flex h-100 mt-5 mb-2">
        <Row className="justify-content-center align-self-center w-100">
          <Col md={9} lg={7}>
            <CardEmployee>
              <InsideCardStyles className=' d-flex flex-column align-items-center'>

                <StyledCardTitleEmployee>Como está a sua <br />felicidade no trabalho?</StyledCardTitleEmployee>
                <StyledCardSubtitle className='mt-4'>Sua percepção é muito importante!</StyledCardSubtitle>
                <div className="d-flex align-items-center justify-content-center" style={{

                  marginBottom: 20,
                }}>
                  <StyledCardText>
                    A seguir você encontrará algumas perguntas sobre aspectos da relação que você tem com seu ambiente de trabalho, com você mesmo, com as pessoas a sua volta e com a instituição.<br /><br />Sua percepção ajuda você e a organização na promoção de um ambiente de trabalho mais feliz, saudável e sustentável para você e também para os seus colegas!<br /><br />Vamos lá?
                  </StyledCardText>
                </div>
                <StartButton onClick={async () => {
                  if (user.verifyToken) {
                    

                    try {
                      if(!user || !user.user || !user.user.company_id || !user.user.email) {
                        showToast('Sua sessão expirou!\n\nPor questões de segurança, o seu acesso expira depois de certo tempo.', 'info', 7500)
                        setTimeout( () => {
                            showToast('Você será redirecionado para a tela de login em 5 segundos', 'info', 5000)
                            setTimeout( () => {                                
                                SignOut();
                            }, 5000);
                        }, 2500);
                        return;
                      }

                      const company = await getCompanyId(user.user.company_id)
                      const updatedUser = await getUserByEmail(user.user.email)
                      const updatedStatus = updatedUser.status;
                      
                      if (updatedStatus !== user.user.status) {
                        user.user.status = updatedStatus;
                        saveDataSignUpOnStorage(user.user)
                      }

                      if (updatedStatus !== UserStatus.active && updatedStatus !== UserStatus.invited) {
                        showToast('Sua conta está inativada.\n\nPara reativá-la peça ao Gestor de Felicidade da sua organização ou fale com nosso suporte pelo botão de ajuda no canto inferior direito da tela.', 'info', 18000)
                        return;
                      }
                      
                      if (!company.Item.surveys_all_month) {
                        const today = new Date()
                        const day = getDate(today)
                        const lastDayOfMonth = getDate(endOfMonth(today))

                        if (!(day >= 20 && day <= lastDayOfMonth)) {
                          showToast('Estamos fora do periodo de reflexão :(\n\nPara responder a reflexão do mês, você deve acessar durante o período do dia 20 até o último dia do mês.', 'info', 20000)
                        } else {
                          navigate('/questions')
                        }
                      } else {
                        navigate('/questions')
                      }

                    } catch (error: any) {
                      if (error.response && error.response.data && error.response.data.error) {
                        switch(error?.response?.data?.error) {
                          case "Erro ao validar o token - zenbox_backend":
                            showToast('Sua sessão expirou!\n\nPor questões de segurança, o seu acesso expira depois de certo tempo.', 'info', 7500)
                            setTimeout( () => {
                                showToast('Você será redirecionado para a tela de login em 5 segundos', 'info', 5000)
                                setTimeout( () => {                                
                                    SignOut();
                                }, 5000);
                            }, 2500);
                          
                            break;
                            
                          default:
                            showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000);
                            throw error;
                        }

                      } else {
                        showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000)
                        throw error;
                      }

                    }


                  } else {
                    navigate('/signup')
                  }
                }} >Começar</StartButton>
              </InsideCardStyles>
            </CardEmployee>
          </Col>
        </Row>
      </Container>


    </>








  )
}

export default Welcome;

