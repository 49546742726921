import { Card, CardBody, Col, Row } from "react-bootstrap"
import Vector from "../../../assets/Vector.svg";
import VectorRed from "../../../assets/Vector Red.svg";
import CountUp from 'react-countup';
import { CardAttentionPoints, CardAttentionPointsContainer, CardAttentionPointsContent, StyledCardHeader, StyledCardBody, StyledCardParagraph, StyledCardHeaderAttentionCards } from "./styles";
import { SvgIcon } from "@mui/material";
import { CircleColor } from "../dimension/styles";


interface IAttentionPoints {
    date:string;
    data:any;
    title: string;
    filterTag?: string | null;
}


const AttentionPoints: React.FC<IAttentionPoints>= ({date, data, title, filterTag}) => {

    const IndicatorIconGreen = () => {
        return (
            <SvgIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="21" viewBox="0 0 28 21" fill="none">
                    <path d="M2.11969 20.2948L25.7585 20.2948C25.9979 20.294 26.2325 20.228 26.4371 20.1038C26.6417 19.9797 26.8085 19.8021 26.9197 19.5901C27.0309 19.3782 27.0822 19.1399 27.068 18.901C27.0538 18.6621 26.9748 18.4316 26.8394 18.2342L15.0199 1.16175C14.5301 0.453894 13.3508 0.453894 12.8596 1.16175L1.04018 18.2342C0.903373 18.4312 0.823144 18.6618 0.808214 18.9011C0.793283 19.1404 0.844222 19.3793 0.955495 19.5917C1.06677 19.804 1.23412 19.9819 1.43937 20.1058C1.64462 20.2298 1.87991 20.2951 2.11969 20.2948Z" fill="url(#paint0_linear_5420_37455)"/>
                    <defs>
                    <linearGradient id="paint0_linear_5420_37455" x1="31.3193" y1="11.2178" x2="19.2891" y2="-6.42152" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00B2B5"/>
                    <stop offset="1" stopColor="#96FFD2"/>
                    </linearGradient>
                    </defs>
                </svg>
            </SvgIcon> 
        )
        
    }

    const IndicatorIconRed = () => {
        return(
            <SvgIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="21" viewBox="0 0 27 21" fill="none">
                    <path d="M25.2382 0.630527L1.59938 0.630525C1.36005 0.631275 1.12546 0.697284 0.920849 0.821445C0.71624 0.945606 0.54937 1.12322 0.438196 1.33516C0.327022 1.54711 0.275757 1.78536 0.289919 2.02427C0.304079 2.26319 0.383131 2.49372 0.518562 2.69105L12.338 19.7635C12.8278 20.4714 14.0071 20.4714 14.4983 19.7635L26.3177 2.69105C26.4545 2.49413 26.5348 2.26349 26.5497 2.02418C26.5646 1.78486 26.5137 1.54604 26.4024 1.33364C26.2911 1.12125 26.1238 0.943409 25.9185 0.819448C25.7133 0.69549 25.478 0.63015 25.2382 0.630527Z" fill="url(#paint0_linear_5420_37465)"/>
                    <defs>
                    <linearGradient id="paint0_linear_5420_37465" x1="-12.8447" y1="10.4625" x2="6.02334" y2="35.6641" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD4659"/>
                    <stop offset="1" stopColor="#FEA993"/>
                    </linearGradient>
                    </defs>
                </svg>
            </SvgIcon>
        )
    }

    
    function formatDateString(dateString:string) {
        if(!dateString) return ""
        const months:any = {
            '01': 'Janeiro',
            '02': 'Fevereiro',
            '03': 'Março',
            '04': 'Abril',
            '05': 'Maio',
            '06': 'Junho',
            '07': 'Julho',
            '08': 'Agosto',
            '09': 'Setembro',
            '10': 'Outubro',
            '11': 'Novembro',
            '12': 'Dezembro'
        };
    

        const [year, month] = dateString.split('-');
        return `${months[month]}/${year}`;
    }
    return (
        <StyledCardBody style={{ background: '#FFF', color:'#3C4043' }}>
            <StyledCardHeaderAttentionCards  style={{borderRadius: '.7em .7em 0 0 '}}>
                <StyledCardParagraph className='pb-0'>
                    {title} {filterTag ? ` (${filterTag})` : ""}
                </StyledCardParagraph>
            </StyledCardHeaderAttentionCards>

            <Card.Body className="px-xxl-0 pt-4 pb-4">
                <CardAttentionPointsContainer>

                    <div>
        
                        {(data > 0 || data < 0) ? (
                            <div style={{display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center'}}>
                               <div style={{display:'flex', alignItems:'center'}}>
                                    {data > 0 ? <IndicatorIconGreen /> : data < 0 ? <IndicatorIconRed /> : ''}
                                    <CountUp
                                        style={{paddingLeft:'8px', fontSize:'20px', color: '#878B8E'}}
                                        start={0}
                                        end={data}
                                        duration={2.75}
                                        prefix={data > 0 ? "+" : "-" }
                                        suffix="%"
                                        separator=","
                                        decimals={1}
                                        decimal=","
                                    />
                                </div> 
                                {data > 0 ? (
                                    <span style={{fontWeight: 'bold',color: '#25BB90', marginTop: '.7em'}}>Aumentou</span>
                                ) : (
                                    <span style={{fontWeight: 'bold',color: '#FE7374', marginTop: '.7em'}}>Diminuiu</span>
                                )}  

                            </div>
                            
                        ) : (
                            
                            <div style={{display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center'}}>
                                <div style={{display:'flex', alignItems:'center', justifyContent:'center', gap: '.5em'}}>	
                                    
                                    <CircleColor style={{background:'#c9c9c9', marginBottom: '1em' }}></CircleColor>
                                   
                                   <p style={{color:'#878B8E'}}> - - </p>
                                   
                                    
                                </div>
                                
                                <span style={{fontWeight: 'bold',color: '#878B8E'}}>Não mudou</span>
                            </div> 
                        ) }

                    </div>

                </CardAttentionPointsContainer>

            </Card.Body>
        </StyledCardBody>
    )
}

export default AttentionPoints;