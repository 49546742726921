import React, { useEffect, useState } from 'react';
import questions from '../../data/questions.json';
import { useQuestions } from '../../context/QuestionContext';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import {
    BackButton, NextButton, QuestionCard, SliderContainer, SliderLabel, SliderLabelContainer, SliderTicks, StyledCardSubtitle, StyledCardText, StyledInput, StyledTextArea, ContainerBtnNextBtnPrev, StyledSkip, getEndColor, getInitialColor, finalColorSlider, SliderLabelContainerResponsive
    , SliderLabelResponsive, ContainerSlider, ButtonSkip, StyledFinalQuestion, StyledCardSubtitleFinalMsg, 
    SpanNextButton
} from './styles';
import { ReactComponent as IconSkip } from '../../assets/akar-icons_arrow-back.svg'
import { StyledCardTitle, StyledCardTitleEmployee, StyledCardSubtitle as Subtitle } from '../../pages/welcome/styles';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';


import { updateAnswers } from '../../services/question/question';
import { useToast } from '../../context/Toast';
import { Spinner } from 'react-bootstrap';
import { useAuth } from '../../context/AuthContext';
import { Tooltip } from '@mui/material';
export interface IQuestion {
    id: number;
    question: string;
    type: string
    dimension?: string;
}

interface RenderFieldProps {
    entity: IQuestion | null;
}



const RenderField: React.FC<RenderFieldProps> = ({ entity }) => {
    const { saveResponse,
        responses,
        currentQuestionIndex,
        setCurrentQuestionIndex,
        answerId,
        removeAnswer
    } = useQuestions();
    const { showToast } = useToast()
    const totalQuestions = questions.length;
    const [inputValue, setInputValue] = useState<any>(0);
    const navigate = useNavigate()
    const [questionAnswered, setQuestionAnswered] = useState<boolean>(false);
    const { SignOut } = useAuth()
    const longTextTypeQuestion = currentQuestionIndex !== null ? questions[currentQuestionIndex] : null;

    useEffect(() => {

        setInputValue(0);
        
        // Ativa o botão de próximo caso o usuário responda a primeira questão, volte para o welcome e reinicie o form
        if (currentQuestionIndex == 0 && responses[currentQuestionIndex + 1])
            setQuestionAnswered(true);

        if (longTextTypeQuestion && longTextTypeQuestion.type === 'long_text') {
            const answer = responses[longTextTypeQuestion.id] || ''; //pega a resposta atual
            const isAnswered =  longTextTypeQuestion.type === "long_text" ? answer.trim().length > 0 : true; //checa se a resposta não está vazia
            setQuestionAnswered(isAnswered); //atualiza o estado de validação
        }


    }, [entity, currentQuestionIndex, longTextTypeQuestion]);

    
    


    const [loading, setLoading] = useState(false)
    const handleAnswer = (questionId: number, answer: any, type?:string ) => {
        saveResponse(questionId, answer);
        setQuestionAnswered( type === "long_text" ? answer.trim().length > 0 :  true);
        setNumberColors(calculateNumberColors(answer));
        setUserColorsInteracted(true); 
    };

    const [isFinished, setIsFinished] = useState(false)
    const sliderRef = useRef<HTMLInputElement>(null);
    const [thumbColor, setThumbColor] = useState('radial-gradient(circle, #545454 55%, #464646 45%)');


    //para a implementação dos numeros ficarem de acordo com o slider (efeito acinzentado se não for o valor do input)
    const initialNumberColors = [' #FFF', ' #FFF', ' #FFF', ' #FFF', ' #FFF'];
    const [numberColors, setNumberColors] = useState<string[]>(initialNumberColors);
    //para a implementação dos numeros ficarem de acordo com o slider (efeito acinzentado se não for o valor do input)
   

    // faz essa diferença entre o valor do input com os outros valores deixando os valores que não são selecionados em cinza
    const calculateNumberColors = (savedValue: string | undefined): string[] => {
        const value = savedValue ? parseInt(savedValue, 10) : 1;
        return Array.from({ length: 5 }, (_, index) =>
          index + 1 === value ? ' #FFF' : 'rgba(255, 255, 255, 0.30)'
        );
    };
    // faz essa diferença entre o valor do input com os outros valores deixando os valores que não são selecionados em cinza

    const [userColorsInteracted, setUserColorsInteracted] = useState<boolean>(false);

    useEffect(() => {
        if (userColorsInteracted) {
            setNumberColors(calculateNumberColors(responses[entity!.id]));
            setInputValue(parseInt(responses[entity!.id], 10) || 1);
        } else {
            const savedValue = responses[entity!.id];
            if (savedValue != null) { // deixa o numero que o usuario escolheu em branco e o resto em cinza quando volta a pergun
                setNumberColors(calculateNumberColors(savedValue));
                setInputValue(parseInt(savedValue, 10));
            }

            //implementar depois
            // if(savedValue === "200"){
            //     setNumberColors(Array.from({ length: 5 }, () => '#fff')); //deixa os numeros brancos ao passar a pergunta
            // }

            // if(savedValue === "100"){
            //     setNumberColors(Array.from({ length: 5 }, () => '#fff'));
            // }
            //implementar depois   
            

        } 
    }, [currentQuestionIndex, userColorsInteracted, responses, entity]);
    

  
    
      


    const handleNextQuestion = () => {
        if (currentQuestionIndex == null) {
            setCurrentQuestionIndex(0);
        } else if (currentQuestionIndex < totalQuestions - 1 && questionAnswered) {

            // if (entity && (!responses[entity.id] || responses[entity.id] == '100')) handleAnswer(entity.id, '200')

            //parte que faz com que resete o css do input a cada pergunta
            if (sliderRef.current) {
                sliderRef.current.style.background = 'linear-gradient(180deg, #999 0%, rgba(196, 196, 196, 0.50) 100%)'
            }
            setInputValue(1);
            setCurrentQuestionIndex(currentQuestionIndex + 1);

           
            //se o usuário voltar muitas perguntas e depois avançar essa condicional faz com que o rastro do input não desapareça
            const nextQuestionId = questions[currentQuestionIndex + 1].id;
            const savedQuestionValue = responses[nextQuestionId];
            if (sliderRef.current) {
                if (savedQuestionValue !== undefined) {
                    const value = parseInt(savedQuestionValue, 10);
                    const initialColor = getInitialColor(value);
                    const endColor = getEndColor(value);
                    const gradientPosition = ((value - 1) * 100) / 4;
                    sliderRef.current.style.background = `linear-gradient(to right, ${initialColor} 0%, ${endColor} ${gradientPosition}%, #999 0%, rgba(196, 196, 196, 0.50) 100%)`;
                    setQuestionAnswered(true);
                } else {
                    sliderRef.current.style.background = 'linear-gradient(180deg, #999 0%, rgba(196, 196, 196, 0.50) 100%)';
                    setUserColorsInteracted(false) 
                    setNumberColors(Array.from({ length: 5 }, () => ' #FFF')); //deixa os numeros brancos ao passar a pergunta
                    const newColor = 'radial-gradient(circle, #545454 55%, #464646 45%)'; // Nova cor do thumb (pode ser alterada conforme necessário)
                    setThumbColor(newColor);
                    setQuestionAnswered(false);
                }
            }
            
        }   
    };

    const onSubmit = async (flag: string) => {

        const transformedArray = Object.entries(responses).map(([key, value]) => ({
            id: parseInt(key),
            order: key,
            answer: value
        }));

        const id26Exists = transformedArray.some(item => item.id === 26);


        if (!id26Exists) {
            if (flag === 'skip') {
                transformedArray.push({
                    id: 26,
                    order: "26",
                    answer: '100'
                });
            } else if (flag === 'next') {
                transformedArray.push({
                    id: 26,
                    order: "26",
                    answer: '200'
                });
            }
        }



        const dataToSend = {
            id: answerId,
            answer: transformedArray
        }

        try {
            setLoading(true)
            await updateAnswers(dataToSend)
            setIsFinished(true);
            setLoading(false);
            removeAnswer()

        } catch (error: any) {
            setLoading(false)

            if (error.response) {
                if (error.response.status === 401) {
                    showToast('Sua sessão expirou!\n\nPor questões de segurança, o seu acesso expira depois de certo tempo.', 'info', 7500)
                    setTimeout( () => {
                        showToast('Você será redirecionado para a tela de login em 5 segundos', 'info', 5000)
                        setTimeout( () => {                                
                            SignOut();
                        }, 5000);
                    }, 2500);

                } else if (error.response.status === 403) {
                    showToast('Ops! Parece que você não tem permissão para isso :(\n\nVerifique se você está utilizando a conta correta. Caso precise de mais informações, entre em contato com nosso suporte clicando no botão de "Ajuda" na parte inferior da sua tela.', 'error', 30000)

                } else if (error.response.data && error.response.data.error) {
                    switch(error.response.data.error) {
                      case "Erro ao validar o token - zenbox_backend":
                        showToast('Sua sessão expirou!\n\nPor questões de segurança, o seu acesso expira depois de certo tempo.', 'info', 7500)
                        setTimeout( () => {
                            showToast('Você será redirecionado para a tela de login em 5 segundos', 'info', 5000)
                            setTimeout( () => {                                
                                SignOut();
                            }, 5000);
                        }, 2500);
                        break;
                        
                      default:
                        showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000);
                        throw error;
                    }
                
                } else {
                    showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000)
                    throw error
                }
            } else {
                showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000)
                throw error
            }
        }
    }

    const skipQuestion = () => {

        if (currentQuestionIndex == null) {
            setCurrentQuestionIndex(0);
        } else if (currentQuestionIndex < totalQuestions - 1) {
            if (entity) handleAnswer(entity.id, '100')
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            //parte que faz com que resete o css do input a cada pergunta
            if (sliderRef.current) {
                sliderRef.current.style.background = 'linear-gradient(180deg, #999 0%, rgba(196, 196, 196, 0.50) 100%)'; // limpa o estilo do controle deslizante

                //Parte que deixa o ponteiro preto ao clicar pra avançar pegunta
                const defaultColor = 'radial-gradient(circle, #545454 55%, #464646 45%)'; // Nova cor do thumb (pode ser alterada conforme necessário)
                setThumbColor(defaultColor); // cor padrão do slider
                //Parte que deixa o ponteiro preto ao clicar pra avançar pegunta
            }

            setUserColorsInteracted(false) 
            setNumberColors(Array.from({ length: 5 }, () => '#fff')); //deixa os numeros brancos ao passar a pergunta

            setInputValue(1);
        }

    };


    const handlePreviousQuestion = () => {
        if (currentQuestionIndex === 0) {
            navigate('/welcome')
        } else if (currentQuestionIndex !== null && currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
            setQuestionAnswered(true)
            
            const previousQuestionId = questions[currentQuestionIndex - 1].id;
            const savedPreviousQuestionValue = responses[previousQuestionId];
            
            if (sliderRef.current) {
              sliderRef.current.style.background = 'linear-gradient(180deg, #999 0%, rgba(196, 196, 196, 0.50) 100%)';
            }

            if (savedPreviousQuestionValue !== undefined) {
                if (sliderRef.current) {
                    const value = parseInt(savedPreviousQuestionValue, 10);
                    const initialColor = getInitialColor(value);
                    const endColor = getEndColor(value);
                    const gradientPosition = ((value - 1) * 100) / 4;
    
                    sliderRef.current.style.background = `linear-gradient(to right, ${initialColor} 0%, ${endColor} ${gradientPosition}%, #999 0%, rgba(196, 196, 196, 0.50) 100%)`;
                }
            } else {
                if (sliderRef.current) {
                    setNumberColors(Array.from({ length: 5 }, () => '#fff'));
                    sliderRef.current.style.background = 'linear-gradient(180deg, #999 0%, rgba(196, 196, 196, 0.50) 100%)';
                }
            }
        }
    };

    const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const sliderValue = parseInt(event.target.value, 10);
        // define a cor do polegar baseado no valor do slider
        
        setThumbColor(finalColorSlider(sliderValue));
        setInputValue(sliderValue);
        handleAnswer(entity!.id, sliderValue);

        // const newNumberColors = numberColors.map((color, index) =>
        // index + 1 === sliderValue ? '#fff' : 'rgba(255, 255, 255, 0.3)'
        // );

        // setNumberColors(newNumberColors);
        
    };


    const renderQuestionInput = (question: IQuestion) => {
        const savedValue = responses[question.id];

        const value = parseInt(savedValue, 10);//variaveis para a definir a cor do rastro
        const initialColor = getInitialColor(value);//variaveis para a definir a cor do rastro
        const endColor = getEndColor(value);//variaveis para a definir a cor do rastro
        const gradientPosition = ((value - 1) * 100) / 4;//variaveis para a definir a cor do rastro e o tamanho
        const thumbColor = finalColorSlider(value); //variaveis para definir a cor do ponteiro do slider

        const handleInteraction = (e: React.SyntheticEvent<HTMLInputElement>) => {
            const target = e.target as HTMLInputElement;
            const newValue = parseInt(target.value, 10);
            setInputValue(newValue);
            setThumbColor(finalColorSlider(newValue));
            handleSliderChange(e as React.ChangeEvent<HTMLInputElement>);
            handleAnswer(entity!.id, newValue);
            setUserColorsInteracted(true);
        };

        
        

        switch (question.type) {
            case 'scale':
                return (

                    <ContainerSlider style={{ width: '100%' }} >
                        <SliderContainer>
                            <SliderLabelContainer>
                                <SliderLabel>Pouco</SliderLabel>
                                <SliderLabel>Muito</SliderLabel>
                            </SliderLabelContainer>
                            <SliderLabelContainerResponsive>
                                <SliderLabelResponsive>Pouco</SliderLabelResponsive>
                                <StyledInput
                                    ref={sliderRef}
                                    type="range"
                                    min={1}
                                    max={5}
                                    id={`range-${question.id}`}
                                    step={1}
                                    value={(savedValue === '100' || savedValue === '200' || inputValue === '100' || inputValue === '200') ? 0 : (savedValue || inputValue)}
                                    onClick={(e)=>{ handleInteraction(e)  }}  //evento de clique para mudar a cor do ponteiro de preto para vermelho caso o usuário interaja com o numero 1
                                    onTouchEnd={(e)=>{ handleInteraction(e)}}
                                    onChange={(e) => {
                                        handleSliderChange(e);
                                        setInputValue(e.target.value);
                                        handleAnswer(question.id, e.target.value);

                                    }}
                                    style={{
                                        //estilização em linha (por enquanto) dos rastros do slider utilizando as variaveis acima
                                        background: `linear-gradient(to right, ${initialColor} 0%, ${endColor} ${gradientPosition}%, #999 0%,  rgba(196, 196, 196, 0.50) 100%)`,

                                    } as React.CSSProperties}
                                />
                                <SliderLabelResponsive>Muito</SliderLabelResponsive>
                            </SliderLabelContainerResponsive>
                            {/* //estilização em linha (por enquanto) da cor vermelha ao clique */}
                            <style>
                                {` 
                                input[type='range']::-webkit-slider-thumb {  
                                    background: ${thumbColor};
                                }

                                input[type='range']::-moz-range-thumb {  
                                    background: ${thumbColor};
                                }
                                `}
                            </style>
                            {/* //estilização em linha (por enquanto) da cor vermelha ao clique */}

                            <SliderTicks className='mt-4'>
                            {numberColors.map((color, index) => (
                                <span key={index} style={{ color }}>{index + 1}</span>
                            ))}
                            </SliderTicks>
                        </SliderContainer>
                    </ContainerSlider>
                );
            case 'short_text':
                return (
                    <StyledTextArea
                        id={`short_text-${question.id}`}
                        type='text'
                        value={savedValue ? savedValue : ''}
                        rows="1"
                        onChange={(e) => handleAnswer(question.id, e.target.value)}
                    />
                );
            case 'long_text':
                return (
                    <StyledFinalQuestion
                        id={`long_text-${question.id}`}
                        type='textarea'
                        value={savedValue ? savedValue : ''}
                        placeholder='Escreva sua resposta aqui...'
                        rows="1"
                        onChange={(e) => handleAnswer(question.id, e.target.value, 'long_text')}
                    />
                );
            case 'radio':
                return (
                    <>
                        <Form>
                            <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <FormGroup check inline style={{ marginRight: '65px' }}>
                                    <Label check style={{ display: 'flex', alignItems: 'center' }}>
                                        <Input
                                            type="radio"
                                            name={`radio-${question.id}`}
                                            value="Sim"
                                            checked={savedValue === "Sim"}
                                            onChange={(e) => {
                                                handleAnswer(question.id, e.target.value);
                                            }}
                                            style={{ marginRight: '6px' }}
                                        />
                                        <StyledCardText >Sim</StyledCardText>
                                    </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                    <Label check style={{ display: 'flex', alignItems: 'center' }}>
                                        <Input
                                            type="radio"
                                            name={`radio-${question.id}`}
                                            value="Não"
                                            checked={savedValue === "Não"}
                                            onChange={(e) => {
                                                handleAnswer(question.id, e.target.value);
                                            }}
                                            style={{ marginRight: '6px' }}
                                        />
                                        <StyledCardText className='ml-3'>Não</StyledCardText>
                                    </Label>
                                </FormGroup>
                            </FormGroup>
                        </Form>

                    </>
                );
            default:
                return <></>;
        }
    };
    if (isFinished) {
        return (
            <QuestionCard className='p-5' style={{
                width: '100%',
                height: '50vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>

                <StyledCardTitleEmployee>Muito obrigado!</StyledCardTitleEmployee>
                <StyledCardSubtitleFinalMsg className='mt-4' style={{}}>Sua percepção é muito importante<br />para nós! 💜✨</StyledCardSubtitleFinalMsg>
            </QuestionCard>
        )

    }
    return (
        <>
            {loading ? (
                <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ minHeight: '300px' }}
                >
                    <Spinner style={{ color: '#c9c9c9' }} />
                </div>
            ) : (
                <QuestionCard>
                    {/* <ButtonSkip disabled={loading} onClick={async () => {
                        if (currentQuestionIndex === totalQuestions - 1) {
                            let flag = ''
                            if (entity && (!responses[entity.id])) flag = 'skip'
                            await onSubmit(flag)
                        }
                        else skipQuestion()
                    }}>

                        <StyledSkip>Pular <IconSkip style={{ width: '15px', height: '15px', marginLeft: 6, marginBottom: 2 }} /></StyledSkip>
                    </ButtonSkip> */}
                    <StyledCardSubtitle>{`Pergunta ${currentQuestionIndex ? currentQuestionIndex + 1 : 1} de ${questions.length}`} {entity?.dimension && (
                        <> • Sobre <strong>{entity.dimension}</strong></>
                    )}</StyledCardSubtitle>
                    <StyledCardText>{entity?.question}</StyledCardText>

                    <div className="d-flex align-items-center justify-content-center" style={{
                        minHeight: '220px',

                    }}>
                        {entity && renderQuestionInput(entity)}

                    </div>
                    <ContainerBtnNextBtnPrev style={{ marginTop: 20 }}>
                        <BackButton disabled={loading} onClick={handlePreviousQuestion }>Anterior</BackButton>
                        <Tooltip title={loading|| !questionAnswered ?(longTextTypeQuestion && longTextTypeQuestion.type === 'long_text' ? "Digite uma resposta para prosseguir" : "Escolha uma resposta acima para prosseguir") : ""} 
                            enterTouchDelay={0} 
                            leaveTouchDelay={2000}
                            disableFocusListener
                            disableInteractive
                            disableHoverListener={!loading && questionAnswered}
                            arrow
                        >
                            <SpanNextButton>
                                <NextButton disabled={loading || !questionAnswered } onClick={async () => {
                                if (currentQuestionIndex === totalQuestions - 1) {
                                    let flag = ''
                                    if (entity && (!responses[entity.id])) flag = 'next'
                                    await onSubmit(flag)
                                }
                                else{
                                    handleNextQuestion()
                                } 
                                }}> {currentQuestionIndex === totalQuestions - 1 ? 'Finalizar' : 'Próxima'}   
                                </NextButton>
                            </SpanNextButton>
                            
                        </Tooltip>
                       
                    </ContainerBtnNextBtnPrev>
                </QuestionCard>
            )}
        </>






    );
};

export default RenderField;
