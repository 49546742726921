import Modal from 'react-bootstrap/Modal'
import { useState, useEffect, useContext, useRef } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { StyledModal, StyledSelect, StyledSelectModalUser } from './styles'
import { StyledInputText, StyledText } from '../styles'
import { Input } from 'reactstrap'
import { LabelInput, LabelNames, StyledOptions } from '../../userRegister/style'
import { useToast } from '../../../context/Toast'
import { UserRole } from '../../../services/utils/enum'
import { signup } from '../../../services/user/user'
import { useAuth } from '../../../context/AuthContext'

import countries from '../../../data/countries.json'

interface IFormState {
  first_name: string;
  last_name: string;
  email: string;
  birth_date: string;
  country: string;
  ethnicity: string;
  newsletter_sub: string;
  role: number;
  sex: string;
}


function ModalAddUser({
  show,
  onClose,
  addItem,
}: any) {

  const { showToast } = useToast()
  const [showLoading, setShowLoading] = useState(false)
  const {user} = useAuth()

  const [form, setForm] = useState<IFormState>({
    first_name: '',
    last_name: '',
    email: '',
    birth_date: '',
    country: '',
    ethnicity: '',
    newsletter_sub: 'N',
    role: UserRole.employee,
    sex: ''
  });


  const handleInputChange = (event: any) => {
    const { name, type, checked } = event.target;

    if (type === "checkbox") {
      setForm({
        ...form,
        [name]: checked ? 'S' : 'N'
      });
    } else {
      setForm({
        ...form,
        [name]: event.target.value
      });
    }
  };

  const isValidDate = (dateString: string) => {
    const date = new Date(dateString);

    return !isNaN(date.getTime()) && new Date(date.toISOString().split('T')[0]).getTime() === date.getTime();
  };

  const onSubmit = async () => {
    setShowLoading(true)
    const company = user.user?.company_id
    if(!company) throw new Error("company_not_found")
    const { birth_date, country, first_name, ethnicity, last_name, sex, email } = form;
    if (!first_name || !last_name || !email) {
      setShowLoading(false)
      return showToast('Os campos Primeiro Nome, Último Nome e Email são obrigatórios.', 'error')

    }
    try {
      let dataSend = {
        email: email,
        password: "Zenbox@life2024"
      }
      try {
        await signup(dataSend)
      } catch (error) {
        console.log(error)
        throw(error)
      }
      const formData = {...form, company:company}
      await addItem(formData)
     

      
      setForm({
        first_name: '',
        last_name: '',
        email: '',
        birth_date: '',
        country: '',
        ethnicity: '',
        newsletter_sub: 'N',
        role: UserRole.employee,
        sex: '',
      })
      
      showToast('Usuário cadastrado', 'success')
      onClose()
    } catch (error) {
      showToast('Falha ao cadastrar usuário', 'error')
    }


    setShowLoading(false)
  }



  if (!show) return null
  return (
    <>
      <StyledModal show={show} size='lg' >
        <Modal.Header>
          <Modal.Title style={{color:'#3C4043'}}>Adicionar usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Row>
            <Col xs={12} sm={6}>
              <Form.Group className="mb-3" controlId="first_name">
                <LabelNames style={{ color:'#3C4043' }}>Primeiro Nome</LabelNames>
                <StyledInputText style={{ background: '#F1F1F1', paddingRight: 10 }}
                  type="text"
                  name="first_name"
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Group className="mb-3" controlId="last_name">
                <LabelNames style={{ color:'#3C4043' }}>Último Nome</LabelNames>
                <StyledInputText style={{ background: '#F1F1F1', paddingRight: 10 }}
                  type="text"
                  name="last_name"
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>



          </Row>

          <Row className='mt-2 '>
            <Col xs={12} sm={6}>
              <Form.Group className="mb-3" controlId="email">
                <LabelNames style={{ color:'#3C4043' }}>Email</LabelNames>
                <StyledInputText style={{ background: '#F1F1F1', paddingRight: 10 }}
                  type="email"
                  name="email"
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>

            <Col xs={12} sm={6}>
               <Form.Group className="mb-3" controlId="country">
                  <LabelNames style={{ color:'#3C4043' }}>País</LabelNames>
                  <StyledSelectModalUser style={{ background: '#F1F1F1', }}
                    type="select"
                    name="country"
                    value={form?.country || ''}
                    onChange={handleInputChange}

                  >
                    <option value='' disabled hidden>Clique para selecionar</option>
                    {countries.map((country) => (
                      <option key={country.nome_pais} value={country.nome_pais}>
                        {country.nome_pais}
                      </option>
                    ))}
                  </StyledSelectModalUser>
                </Form.Group>
            </Col>




          </Row>
          <Row className='mt-2 '>
            <Col xs={12} sm={6}>
              <Form.Group className="mb-3" controlId="birthday">
                <LabelNames style={{ color:'#3C4043', textAlign: "left", }}>Data de Nascimento</LabelNames>
                <StyledInputText style={{ background: '#F1F1F1', paddingRight: 10 }}
                  type="date"
                  name='birth_date'
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>

            <Col xs={12} sm={6}>
            <Form.Group className="mb-3" controlId="sex">
              <LabelNames style={{ color:'#3C4043' }}>Sexo</LabelNames>
              <StyledSelectModalUser style={{ background: '#F1F1F1', height: '46px' }}
                size='sm'
                type='select'
                name='sex'
                value={form?.sex}
                onChange={handleInputChange}>

                <StyledOptions value='' disabled defaultValue='' hidden>Clique para selecionar</StyledOptions>
                <StyledOptions value={'1'}>Masculino</StyledOptions>
                <StyledOptions value={'2'}>Feminino</StyledOptions>
                <StyledOptions value={'3'}>Não informado</StyledOptions>
              </StyledSelectModalUser>
            </Form.Group>
            </Col>
            {/* <Col xs={12} sm={4}>
              <Form.Group className="mb-3" controlId="ethnicity">
                <LabelNames style={{ color: 'white', width: '100%', }}>Etnia</LabelNames>
                <StyledSelect style={{ background: '#2A2A48', height: '46px' }}
                  size='sm'
                  name='ethnicity'
                  onChange={handleInputChange}
                >
                  <option value='' disabled selected hidden>Clique para selecionar</option>
                  <option value={'1'}>Branco</option>
                  <option value={'2'}>Negro</option>
                  <option value={'3'}>Asiático</option>
                  <option value={'4'}>Indígena</option>
                  <option value={'5'}>Multirracial/Mestiço</option>
                </StyledSelect>
              </Form.Group>
            </Col> */}
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{background: 'transparent', borderColor:'#3C4043', color:'#3C4043'}}
            variant='secondary'
            onClick={onClose}
            disabled={showLoading}
          >
            Fechar
          </Button>
          <Button
           style={{background:'linear-gradient(315deg, #2B81FF 12.16%, #2491FF 24.23%, #04D7FF 78.39%)', border:'none', minHeight: '38px'}}
            variant='primary'
            onClick={() => {
              onSubmit()
            }}
            disabled={showLoading}
          >
            {showLoading ? 'Confirmando...' : 'Confirmar'}
          </Button>
        </Modal.Footer>
      </StyledModal>
    </>
  )
}

export default ModalAddUser
