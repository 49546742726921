export enum UserSex {
    male = 1,
    female = 2,
    other = 3
}

export enum UserEthnicity {
    white = 1,
    black = 2,
    asian = 3,
    indigenous = 4,
    mixed_race = 5
}

export enum UserStatus {
    active = 1,
    invited = 2,
    inactive = 3,
    invited_inactive = 4,
    disabled_by_support = 5
}

export enum UserRole {
    sAdmin = 1,
    admin = 2,
    employee = 3
}