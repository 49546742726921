import React, { useState, ReactNode } from "react";
import { useAccordionButton, Accordion } from "react-bootstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { StyledCardHeader } from "../dashboard/attentionPoints/styles";
import { ContainerDimensionName, StyledHeaderDimension, StyledTitle } from "../dashboard/dimension/styles";

interface AccordionProps {
    children: ReactNode;
    title: string;
    accordionKey: string;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    feedbackAccordion?: boolean;
}

const CustomAccordion: React.FC<AccordionProps> = ({children , title, accordionKey, isOpen, setIsOpen, feedbackAccordion: dissertationAccordion}) => {
 

    function CustomToggle({ children, eventKey }: any) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
            setIsOpen(!isOpen)
        );

        return (
            <button
                type="button"
                style={{
                    background: 'transparent',
                    border: 'none',
                    color: 'transparent',
                    outline: 'none',
                    width: '100%',
                    padding: '0',
                }}
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    }

    return(
        <Accordion defaultActiveKey={accordionKey} flush>
        
            <CustomToggle eventKey={accordionKey} >
                {dissertationAccordion ? (
                    <StyledHeaderDimension>
                        <ContainerDimensionName>
                            {!isOpen ? < FaChevronUp  size={20} style={{ color: '#878B8E' }} /> : <FaChevronDown  size={20} style={{ color: '#878B8E' }} />}
                            <StyledTitle>{title}</StyledTitle>
                        </ContainerDimensionName>
                        
                    </StyledHeaderDimension>
                ) : (
                    <StyledCardHeader style={{display:'flex', alignItems:'center', gap: '1em', cursor:'pointer', padding:'1.5em 1em', width:'100%', overflow: 'auto'}}>
                        {!isOpen ? < FaChevronUp  size={20} style={{ color: '#878B8E' }} /> : <FaChevronDown  size={20} style={{ color: '#878B8E' }} />}
                        <h5 style={{color:'#3C4043', margin:'0', textAlign:'left'}}>{title}</h5>
                    </StyledCardHeader>
                )}
                
                
            </CustomToggle>

            <Accordion.Collapse eventKey={accordionKey}>
                {children as React.ReactElement}     
            </Accordion.Collapse>
        
            
        </Accordion>
    )
    

}

export default CustomAccordion;