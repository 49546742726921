import { useState } from "react";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import {
    CardDimension, StyledHeaderDimension, StyledNumberColor, StyledSubtitle, StyledTitle, StyledAccordionOpen, ContainerNumberAndQuestion, CircleColor,
    ContainerHeaderDimensionIFOZ, ContainerDimensionName, NumberIfozDimension, ContainerFilterAndGeneral, ContainerFlexFilterAndGeneral, ParagraphGeneral, ParagraphFilter, StyledSubtitleNumberDimension


} from "./styles";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { FormattedDimensionResults } from "../../../interfaces/dimension.interface";
import Tooltip from "@mui/material/Tooltip";
import { getHumanSustainabilityTagFromIndicator } from "../../../services/statistics/statisticsTools";

type DimensionCardProps = {
    type: string,
    generalData: FormattedDimensionResults | null,
    filteredData?: FormattedDimensionResults | null,
    dimensionKey: string,
};

function getColor(percentage: number | null | undefined): string {
    if (percentage === null || percentage === undefined) {
        return '#c9c9c9';
    }

    const percentageFormatted = parseFloat(percentage.toFixed(1));

    if (percentageFormatted > 0 && percentageFormatted < 40) {
        return "linear-gradient(315deg, #FD4659 0%, #FEA993 100%)";
    } else if (percentageFormatted >= 40 && percentageFormatted < 60) {
        return "linear-gradient(314deg, #FA7F63 4.99%, #FEE073 100%)";
    } else if (percentageFormatted >= 60 && percentageFormatted < 70) {
        return "linear-gradient(314deg, #FFDB23 2.1%, #FFF676 78.35%)";
    } else if (percentageFormatted >= 70 && percentageFormatted < 80) {
        return 'linear-gradient(318deg, #24c58b 16.44%, #96ffa9 85.22%)';
    } else if (percentageFormatted >= 80 && percentageFormatted <= 100) {
        return 'linear-gradient(318deg, #186d4c 16.44%, #0cc93c 85.22%)';
    } else {
        return '#c9c9c9';
    }
}


const Dimension = ({ generalData, filteredData, type, dimensionKey }: DimensionCardProps) => {
    const [cardClicked, setCardClicked] = useState(false);
    function CustomToggle({ children, eventKey }: any) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
            setCardClicked(!cardClicked)
        );

        return (
            <button
                type="button"
                style={{
                    background: 'transparent',
                    border: 'none',
                    color: 'transparent',
                    outline: 'none',
                    padding: '0',
                }}
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    }
    return (
        <Accordion defaultActiveKey={dimensionKey} flush >
            <div style={{ overflowY: 'hidden' }}>
                <ContainerFilterAndGeneral>
                    <ContainerFlexFilterAndGeneral>

                    <Tooltip placement="top" title={generalData?.totalIfoz ? getHumanSustainabilityTagFromIndicator(generalData?.totalIfoz) : ''} arrow enterTouchDelay={0} leaveTouchDelay={2000} 
                                    slotProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, -8]
                                                    }
                                                }
                                            ]
                                        }
                        }}>
                            <div>
                                <ParagraphGeneral>GERAL</ParagraphGeneral>
                            </div>
                        </Tooltip>

                        {/* FILTRO */}
                        {/* Remover o nome filtro usando style, com operador ternario ex: abaixo */}
                        <Tooltip placement="top" title={filteredData?.totalIfoz ? getHumanSustainabilityTagFromIndicator(filteredData?.totalIfoz) : ''} arrow enterTouchDelay={0} leaveTouchDelay={2000} 
                                slotProps={{
                                    popper: {
                                        modifiers: [
                                            {
                                                name: 'offset',
                                                options: {
                                                    offset: [0, -8]
                                                }
                                            }
                                        ]
                                    }
                            }}>
                            <div style={{ display: filteredData?.totalIfoz ? 'block' : 'none' }}>
                                <ParagraphFilter>FILTRO</ParagraphFilter>
                            </div>
                        </Tooltip>
                        {/* FILTRO */}

                    </ContainerFlexFilterAndGeneral>
                </ContainerFilterAndGeneral>

                <CardDimension >
                    {/* Início da tag do header do Accordion */}
                    <CustomToggle eventKey={dimensionKey}>
                        <StyledHeaderDimension>
                            <ContainerDimensionName>
                                {!cardClicked ? < FaChevronUp size={20} style={{ color: '#878B8E' }} /> : <FaChevronDown size={20} style={{ color: '#878B8E' }} />}
                                <StyledTitle>{type}</StyledTitle>
                            </ContainerDimensionName>

                            <div style={{ display: 'flex' }}>
                                {/* GERAL */}
                                {generalData?.totalIfoz && (
                                    <ContainerHeaderDimensionIFOZ style={{}}>
                                        <CircleColor style={{ background: getColor(generalData?.totalIfoz), }} />
                                        <NumberIfozDimension> {generalData.totalIfoz.toFixed(1)}%</NumberIfozDimension>
                                    </ContainerHeaderDimensionIFOZ>
                                )}
                                {/* GERAL */}


                                {/* FILTRO */}
                                {filteredData?.totalIfoz && (
                                    <ContainerHeaderDimensionIFOZ style={{ display: filteredData?.totalIfoz ? 'flex' : 'none' }}>
                                        {/* Remover o nome ifoz total do filtro usando style, com operador ternario ex: acima */}
                                        <CircleColor style={{ background: getColor(filteredData?.totalIfoz), }} />
                                        <NumberIfozDimension> {filteredData?.totalIfoz?.toFixed(1)}%</NumberIfozDimension>
                                    </ContainerHeaderDimensionIFOZ>
                                )}
                                {/* FILTRO */}
                            </div>
                        </StyledHeaderDimension>
                    </CustomToggle>
                    {/* Fim da tag do header do Accordion */}


                    {/* Aqui começa o mapeamento dos indicadores de cada questão */}
                    {generalData?.questionsResults && generalData.questionsResults.length > 0 ? generalData.questionsResults.map((item, index) => (
                        index !== generalData.questionsResults.length && (
                            <Accordion.Collapse key={index} style={{ background: '#FFF' }} eventKey={dimensionKey}>
                                <StyledAccordionOpen>

                                    {/* Numero da pergunta e a pergunta */}
                                    <ContainerNumberAndQuestion >
                                        <StyledSubtitleNumberDimension style={{ color: '#A5A5AA' }}>{`D${generalData.dimensionId}.${index + 1}`}</StyledSubtitleNumberDimension>
                                        <StyledSubtitle>{item?.question}</StyledSubtitle>
                                    </ContainerNumberAndQuestion>
                                    {/* Numero da pergunta e a pergunta */}


                                    <div style={{ display: 'flex' }}>
                                        {/* GERAL */}
                                        <Tooltip title={item?.value ? getHumanSustainabilityTagFromIndicator(item.value) : ''} placement='top' arrow enterTouchDelay={0} leaveTouchDelay={2000}
                                            slotProps={{
                                                popper: {
                                                    modifiers: [
                                                        {
                                                            name: 'offset',
                                                            options: {
                                                                offset: [0, -34]
                                                            }
                                                        }
                                                    ]
                                                }
                                            }}>
                                            <StyledNumberColor >
                                                <CircleColor style={{ background: getColor(item?.value), }} />
                                                <NumberIfozDimension>{item?.value ? `${item?.value.toFixed(1)}%` : `- -`}</NumberIfozDimension>
                                            </StyledNumberColor>
                                        </Tooltip>
                                        {/* GERAL */}



                                        {/* FIlTRO */}
                                        {/* Remover o nome ifoz total do filtro usando style, com operador ternario ex: abaixo */}
                                        <Tooltip title={item?.value ? getHumanSustainabilityTagFromIndicator(filteredData?.questionsResults[index]?.value) : ''} arrow enterTouchDelay={0} leaveTouchDelay={2000} 
                                            slotProps={{
                                                popper: {
                                                    modifiers: [
                                                        {
                                                            name: 'offset',
                                                            options: {
                                                                offset: [0, -34]
                                                            }
                                                        }
                                                    ]
                                                }
                                            }}>
                                            <StyledNumberColor style={{ display: filteredData?.totalIfoz ? 'flex' : 'none' }} >
                                                <CircleColor style={{ background: getColor(filteredData?.questionsResults[index]?.value) }} />
                                                <NumberIfozDimension>{filteredData?.questionsResults[index]?.value ? `${filteredData?.questionsResults[index]?.value?.toFixed(1)}%` : `- -`}</NumberIfozDimension>
                                            </StyledNumberColor>
                                        </Tooltip>
                                        {/* FIlTRO */}

                                    </div>
                                </StyledAccordionOpen>
                            </Accordion.Collapse>
                        )
                    )) : (
                        <Accordion.Collapse style={{ background: '#FFF' }} eventKey={dimensionKey}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                flexDirection: 'row',
                                paddingLeft: '35px',
                                paddingRight: '16px',
                                borderTop: '8px solid #F5F7F8',
                                width: '100%',
                            }}>
                                <div>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '1em' }}>
                                        <h6 style={{ margin: 0, padding: '12px', color: '#878B8E' }}>{'Sem informação'}</h6>
                                    </div>
                                </div>
                            </div>
                        </Accordion.Collapse>
                    )}
                </CardDimension>
            </div>
        </Accordion>
    )
}

export default Dimension